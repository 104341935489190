<template>
  <div>
    <div class="foot">
      <div class="left animated " :class="{fadeInLeft : show}">
        <div class="title">CONTACT</div>
        <div class="contact">
          <div class="text">公司：南昌多安科技有限公司</div>
          <div class="text">电话：0791—85230835</div>
          <div class="text">邮箱：ncdakj@163.com</div>
          <div class="text">地址：南昌市VR产业基地</div>
        </div>
      </div>
      <div class="cen animated" :class="{fadeInDown : show}">
        <div class="title">公司旗下产品</div>
        <div class="product">
<!--          <div class="item" v-for="item in product" :key="item.index">-->
<!--            {{ item }}-->
<!--          </div>-->
          <div class="events" >
            <div class="event1" >
              <p>{{product[0]}}</p>
              <p>{{product[1]}}</p>
              <p>{{product[2]}}</p>
            </div>
            <div class="event2" >
              <span>{{product[3]}}</span>
              <span>{{product[4]}}</span>
              <span>{{product[5]}}</span>
            </div>
          </div>
        </div>
        <div class="copyright">
          Copyright@2020 赣ICP备17017588号-2
        </div>

      </div>
      <div class="right animated" :class="{fadeInRight : show}">
        <div class="title">公司案例</div>
        <img class="img" src="../assets/img/business/qrcode.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      show:false,
      product: [
        "医务一体化平台",
        "等级医院评审管理系统",
        "制度考学审批管理系统",
        "医院预决算及合同审批管理系统",
        "医疗指标监管分析系统",
        "不良事件上报管理系统",
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleScroll(){
      var scrollTop = document.documentElement.scrollTop;
      var windowHeight = document.documentElement.clientHeight;
      var scrollHeight = document.documentElement.scrollHeight
      if(scrollTop+windowHeight===scrollHeight){
        this.show=true;
      }else {
        this.show=false;
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll)
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.foot {
  height: 240px;
  background: #373f5e;
  color: #fff;
  display: flex;
  justify-content: space-around;
  .title {
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-top: 28px;
  }

  .left {
    width: 220px;
    height: 210px;
    .contact {
      margin-top: 28px;
      .text {
        color: #8e93a4;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .cen {
    width: 920px;
    height: 210px;
    .product {
      margin-top: 25px;
      height: 70px;
      width: 920px;
      border-bottom: 1px solid #8e93a4;
      .events{
        width: 100%;
        .event1{
          width: 60%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          text-align: center;
        }
        .event2{
          margin: 0 auto;
          width: 75%;
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          text-align: center;
        }
      }
      .item {
        width: 20%;
        color: whitesmoke;
        font-size: 20px;
      }
      //.item:hover {
      //  color: #ef8200;
      //}
    }
    .copyright {
      margin-top: 28px;
      font-size: 18px;
      color: #8e93a4;
      text-align: center;
    }
  }
  .right {
    width: 120px;
    height: 210px;
    text-align: center;
    .img {
      margin-top: 12px;
      width: 116px;
      height: 116px;
      border-radius: 6px;
    }
  }
}
</style>
