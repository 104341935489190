<template>
  <div class="page">
    <div class="left">
      <img class="logo-1" src="../assets/img/components/logo-1.png" @click="home" />
    </div>
    <div class="nav">
      <div class="item" v-for="(item, index) in navList" :class="{ 'router-link-active': item.path === curPath }" :key="index" @click="nav(item, item.path)" router-link>
        {{ item.name }}
        <div class="popover-box">
          <div class="box">
            <div class="item" v-for="(val, key) in menuList" :key="key" @click="nav(val, item.path)">
              <span>{{val.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      curPath: '/index',
      navList: [
        { name: "首页", path: "/index" },
        { name: "业务与案例", path: "/business" },
        { name: "产品简介", path: "/product" },
        { name: "服务团队", path: "/team" },
        { name: "联系我们", path: "/contact" },
      ],
      menuList: [
        { name: "医务一体化平台", path: "/medical" },
        { name: "等级医院评审系统", path: "/review" },
        { name: "医院制度管理系统", path: "/system" },
        { name: "不良事件智能上报", path: "/defective" },
      ],
      incliudeList: ['/medical', '/review', '/system', '/defective'],
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val) {
        if (this.incliudeList.includes(val.path)) {
          this.curPath = '/product'
          return
        }
        this.curPath = val.path
      },
    },
  },
  computed: {},
  methods: {
    home() {
      this.$router.push({
        path: "/index",
      });
      this.curPath = '/index';
    },
    nav(val, curPath) {
      if (val.path == '/product') {
        return
      }
      this.curPath = curPath;
      this.$router.push({
        path: val.path,
      });
    },
  },
  created() { },
  mounted() { },
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  display: flex;
  z-index: 101;
  justify-content: space-between;
  height: 70px;
  background: #fff;
  position: fixed;
  box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
}

.left {
  height: 68px;
  display: flex;
  align-items: center;
  .logo-1 {
    cursor: pointer;
    width: 160px;
    height: 45px;
    margin-left: 160px;
  }
}

.nav {
  width: 492px;
  height: 80px;
  margin-right: 160px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .item {
    position: relative;
    // background: yellow;
    height: 28px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    a {
      text-decoration: none;
      color: #000014;
    }
    .popover-box {
      position: absolute;
      background: #fff;
      width: 200px;
      height: 0;
      overflow: hidden;
      transition: height 0.2s ease;
      .box {
        padding: 20px;
      }
      .item {
        color: #333;
        margin-top: 10px;
        &:first-child {
          margin-top: 0;
        }
        &:hover {
          color: #ef8200;
        }
      }
    }
    &:nth-child(3) {
      &:hover {
        .popover-box {
          height: 180px;
        }
      }
    }
  }
}
.router-link-active {
  font-weight: 500;
  color: #ef8200;
  border-bottom: 2px solid #ef8200;
}
</style>
