import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/index'
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/index/Index.vue"),
  },
  {
    path: "/index_m",
    name: "Index_m",
    component: () => import("../views/index/IndexM.vue"),
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/team/Team.vue"),
  },
  {
    path: "/business",
    name: "Susiness",
    component: () => import("../views/business/Business.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/contact/Contact.vue"),
  },
  {
    path: "/medical",
    name: "medical",
    component: () => import("../views/product/medical.vue"),
  },
  {
    path: "/review",
    name: "review",
    component: () => import("../views/product/review.vue"),
  },
  {
    path: "/system",
    name: "system",
    component: () => import("../views/product/system.vue"),
  },
  {
    path: "/defective",
    name: "defective",
    component: () => import("../views/product/defective.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("../views/demo.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  routes,
});

//处理路由重复报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
